<template>
  <list-condition-template ref="table" :rows="rows" :total="total"
  v-model:list-query="listQuery" :index-column="true" :footer-show="false">
    <template #condition>
      <el-form-item label="月报年份" required>
        <el-date-picker
            v-model="listQuery.year"
            :disabled-date="disabledDate"
            type="year"
            format="YYYY"
            value-format="YYYY"
            placeholder="选择年">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="manufacturerAuth !== 1" label="ODM制造商" required>
        <el-select v-model="listQuery.accountId" placeholder="请选择" clearable filterable>
          <el-option v-for="item in manufacturerList" :key="item.accountId" :label="item.name" :value="item.accountId"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportProductionReport">导出生产月报</el-button>
      </el-form-item>
      <el-form-item style="float: right;" v-if="manufacturerAuth === 1">
        <el-button type="primary" icon="el-icon-plus" @click="createProductionReport">创建生产月报</el-button>
      </el-form-item>
    </template>
    <template #columns>
      <el-table-column label="生产月报" align="center">
        <template #default="scope">
          <span>{{scope.row.month}}月</span>
        </template>
      </el-table-column>
      <el-table-column label="ODM 制造商" align="center" prop="manufacturerName"></el-table-column>
      <el-table-column label="生产种类" align="center" prop="productionType"></el-table-column>
      <el-table-column label="生产总数" align="center" prop="productionTotal"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="handleView(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import ListConditionTemplate from "@/components/templates/listConditionTemplate";
import {ElMessage} from "element-plus";
import adminApi from "@/apis/admin/admin";
import reportApi from '@/apis/report/report'
export default {
  name: "list",
  components: {ListConditionTemplate},
  data() {
    return {
      total: 0,
      manufacturerAuth: 0,
      rows: [],
      listQuery: {
        year: new Date().getFullYear().toString(),
        accountId: '',
        orders: []
      },
      manufacturerList: [],
    }
  },
  created() {
    // this.listQuery.year = new Date().getFullYear();
    if (this.$route.query?.accountId ?? 0) {
      this.listQuery.accountId = this.$route.query?.accountId
      this.listQuery.year = this.$route.query?.year
      this.getList()
    }
    this.getAccountInfo()
  },
  methods: {
    disabledDate(time) {
      const nowDate = new Date();
      return time.getTime() > nowDate.getTime();
    },
    getAccountInfo() {
      adminApi.getAccountInfo().then(res => {
        if (res?.data?.type?.name === 'MANUFACTURER') {
          this.manufacturerAuth = 1
          this.listQuery.accountId = res.data.id;
          this.listQuery.year = this.listQuery.year.substring(0,4)
          this.getList()
        }else {
          this.manufacturerAuth = 0
          // 获取制造商列表
          reportApi.getManufacturers().then(res => {
            this.manufacturerList = res.data
          })
        }
      })
    },
    getList() {
      this.listQuery.year = this.listQuery.year.substring(0,4)
      reportApi.getList(this.listQuery).then(res => {
        if (res.data.length > 0) {
          const groupData = this.dealWithData(res.data)
          for (const data of groupData) {
            data.month = data.list[0].month
            data.manufacturerName = data.list[0].manufacturer.name
            data.productionType = data.list.length
            data.productionTotal = this.sumNum(data.list)
          }
          this.rows = groupData
        }else {
          this.rows = []
        }
      })
    },
    handleQuery() {
      if (!this.listQuery?.year ?? 0) {
        ElMessage.warning('请选择月报年份')
        return
      }
      if (this.manufacturerAuth !== 1 && (!this.listQuery?.accountId ?? 0)) {
        ElMessage.warning('请选择制造商')
        return
      }
      this.getList()
    },
    exportProductionReport() {
      reportApi.exportProductionReport(this.listQuery).then(res => {
        saveAs(res.data, `生产月报.xlsx`)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    createProductionReport() {
      this.$router.push({
        path: '/odm/report/add',
      })
    },
    handleView(row) {
      const data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/odm/report/info',
        query: {
          data: data
        }
      })
    },
    dealWithData(data) {
      let c = [];
      let d = {};
      data.forEach(element => {
        if (!d[element.date]) {  // 按照date分类
          c.push({
            date: element.date,
            list: [element]
          });
          d[element.date] = element;
        } else {
          c.forEach(ele => {
            if (ele.date === element.date) {
              ele.list.push(element);
            }
          });
        }
      });
      return c;
    },
    sumNum(list) {
      let sum = 0
      list.map(item => {
        sum += item.productionNum
      })
      return sum
    }
  }
}
</script>

<style scoped>

</style>
