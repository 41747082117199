import api from '@/apis/api'
/**
 * 月报相关api
 */
export class ReportApi extends api {

    getList (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getReportList`,
            method: 'post',
            data: condition
        })
    }

    getManufacturers(){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getManufacturers`,
            method: 'get'
        })
    }

    getModelList() {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getModelList`,
            method: 'get'
        })
    }

    exportProductionReport(condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/exportProductionReport`,
            method: 'post',
            responseType: 'blob',
            data: condition
        })
    }
}

export default new ReportApi('report')
